import { type FC, useCallback, useMemo } from 'react';

import { Link } from '@vakantiesnl/components/src/__LEGACY__/atoms/Link';
import { useBuildAccoUrlQueryParams } from '@vakantiesnl/components/src/utils/accommodation';
import { useSearchPageEntity } from '@vakantiesnl/services/src/gtm/util';
import { useApplicationStore } from '@vakantiesnl/services/src/stores/applicationStore';
import { useFilterStore } from '@vakantiesnl/services/src/stores/filtersStore';
import { useGlobalFiltersStore } from '@vakantiesnl/services/src/stores/globalFiltersStore';
import { type Search, type MicroCopy, type GTM } from '@vakantiesnl/types';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import styles from './FetchedProductCard.module.css';

const ProductCard = dynamic(() => import('../ProductCard').then((mod) => ({ default: mod.ProductCard })), {
	ssr: false,
});

type FetchedProductCardProps = {
	microCopies: MicroCopy;
	accommodation: Search.CompactAccommodationItem;
	index: number;
	pageType?: GTM.GTMPageType;
	needQueryParams?: boolean;
};

export const FetchedProductCard: FC<FetchedProductCardProps> = ({
	index,
	accommodation,
	microCopies,
	pageType,
	needQueryParams = true,
}) => {
	const currentPath = useRouter().asPath;

	const isBot = useApplicationStore((s) => s.isBot);
	const filters = useFilterStore((s) => s.filters);
	const globalFilters = useGlobalFiltersStore((s) => s.filters);

	const queryParams = useBuildAccoUrlQueryParams(accommodation.offers[0], filters, globalFilters);
	queryParams.set('referURL', currentPath);

	const entity = useSearchPageEntity({
		countrySlugs: filters.countrySlugs,
		regionSlugs: filters.regionSlugs,
		citySlugs: filters.citySlugs,
		themes: filters.themes,
	});

	const onClick = useCallback(() => {
		import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
			import('@vakantiesnl/services/src/gtm/search').then(({ onAccoClick }) => {
				track(
					onAccoClick(
						index + 1,
						accommodation,
						entity,
						false,
						accommodation?.offers[0],
						pageType && pageType,
					),
				);
			}),
		);
	}, [index, accommodation, entity, pageType]);

	const href = useMemo(
		() => ({
			pathname: accommodation.url,
			query: isBot || !needQueryParams ? '' : queryParams.toString(),
		}),
		[accommodation.url, queryParams, isBot, needQueryParams],
	);

	return (
		<Link
			href={href}
			key={index}
			className={styles['card-wrapper']}
			onClick={onClick}
			data-cy="fetchedProductCard"
			target={'_blank'}
		>
			<ProductCard accommodation={accommodation} microCopies={microCopies} />
		</Link>
	);
};
